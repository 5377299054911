import Button, { ButtonTypes } from '@/components/global/button';
import cn from '@/lib/cn';

type BaseProps = {
    className?: string;
    tabIndex?: number;
};

type LinkButtonProps = BaseProps & {
    href?: string;
    className?: string;
};

type ActionButtonProps = BaseProps & {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    className?: string;
    children?: React.ReactNode;
};

export const TryForYourselfActionButton = ({
    className,
    onClick,
    tabIndex,
    children = 'Try it for yourself',
}: ActionButtonProps) => {
    return (
        <Button
            type={ButtonTypes.Action}
            tabIndex={tabIndex}
            onClick={onClick}
            roundedCorners="full"
            color="transparent"
            className={cn(
                'font-brand-md bg-gradient-to-r from-thematic-purple to-thematic-blue text-white hover:text-analyst-lavender-medium visited:text-white transition-colors',
                className
            )}
        >
            {children}
        </Button>
    );
};

export const TryForYourselfLinkButton = ({ className, href = '/analyst', tabIndex }: LinkButtonProps) => {
    return (
        <Button
            type={ButtonTypes.Link}
            tabIndex={tabIndex}
            href={href}
            roundedCorners="full"
            color="transparent"
            className={cn(
                'font-brand-md bg-gradient-to-r from-thematic-purple to-thematic-blue text-white hover:text-analyst-lavender-medium visited:text-white transition-colors',
                className
            )}
        >
            Try it for yourself
        </Button>
    );
};
