import Link from 'next/link';

import cn from '@/lib/cn';

const NONVARIED_BACKGROUND_COLOR = '#22223C'; // cowbell

const getBackgroundColor = (name: string): string => {
    // this function attempts to grab a random color based on the first letter
    // of the name of the organization

    let color = '#6155F6'; // unicorn
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    const COLORS = [
        '#694BFF', // burple
        '#001497', // navy
        '#00B5C4', // teal dark
        '#1B1DD1', // blue
        '#C72D74', // danger
        '#444444', // black light
    ];
    const letterIndex = letters.indexOf(name.toLowerCase().slice(0, 1));
    if (letterIndex !== -1) {
        color = COLORS[letterIndex % COLORS.length];
    }

    return color;
};

// TODO: implement size as a prop, not by taking in `className`s
// Rough current pattern rules of thumb ...
// w/h-4 = text-sm
// w/h-6 = text-base
// w/h-8 = text-xl
// w/h-16 = text-6xl

type LinkProps = { href: string; className?: string; target?: string; rel?: string; title?: string };
type AvatarProps = { url: Optional<string>; name: string; className?: string };
type AvatarImagePlaceholderProps = AvatarProps & { useVariedDefaultColors?: boolean };
type AvatarWrapperProps = AvatarProps & { linkProps?: LinkProps };

const Avatar = ({ url, name, className, useVariedDefaultColors = true }: AvatarImagePlaceholderProps) => {
    if (url) {
        return (
            <div className={cn('bg-white flex justify-center flex-shrink-0 border border-brand-gray-med', className)}>
                <img
                    src={url}
                    alt={name}
                    title={name}
                />
            </div>
        );
    } else {
        return (
            <div
                className={cn(
                    'text-white text-6xl flex flex-shrink-0 items-center justify-center bg-center border border-brand-gray-med',
                    className
                )}
                style={{
                    backgroundColor: useVariedDefaultColors ? getBackgroundColor(name) : NONVARIED_BACKGROUND_COLOR,
                }}
                title={name}
            >
                <span>{name.charAt(0).toUpperCase()}</span>
            </div>
        );
    }
};

const AvatarWrapper = ({ linkProps, ...avatarProps }: AvatarWrapperProps & { useVariedDefaultColors?: boolean }) => {
    if (linkProps) {
        return (
            <Link
                {...linkProps}
                className={cn('no-underline', linkProps.className)}
            >
                <Avatar {...avatarProps} />
            </Link>
        );
    }

    return <Avatar {...avatarProps} />;
};
export const TeamAvatar = (props: AvatarWrapperProps) => {
    return (
        <AvatarWrapper
            {...props}
            useVariedDefaultColors={false}
        />
    );
};

export const UserAvatar = (props: AvatarWrapperProps) => {
    return (
        <AvatarWrapper
            {...props}
            useVariedDefaultColors={true}
        />
    );
};
