import NextLink, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';

import cn from '@/lib/cn';

const defaultStyles = cn(
    // Light styles
    'transition-colors no-underline text-brand-blue-yves visited:text-brand-blue-yves hover:text-brand-blue-nu',
    // Dark styles
    'dark:underline dark:text-brand-lead-ore dark:visited:text-brand-lead-ore dark:hover:text-white'
);

type NavigationLinkProps = LinkProps & {
    target?: string;
    children: ReactNode;
    className?: string;
    tabIndex?: number;
    title?: string;
};

export const NavigationLink = ({ children, className, ...passThroughProps }: NavigationLinkProps) => {
    return (
        <NextLink
            className={cn(defaultStyles, className)}
            {...passThroughProps}
        >
            {children}
        </NextLink>
    );
};

type ActionLinkProps = { children: ReactNode; className?: string; onClick: React.MouseEventHandler<HTMLAnchorElement> };

export const ActionLink = ({ children, className, onClick }: ActionLinkProps) => {
    return (
        <a
            className={cn(defaultStyles, className)}
            {...{ onClick }}
        >
            {children}
        </a>
    );
};
