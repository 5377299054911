import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { CompanyLinks, CopyWrite, LearnLinks, LegalLinks, ProductLinks } from '@/components/global/footer';
import Icon from '@/components/global/icon';
import { LogoWithWordmark } from '@/components/global/logo';
import SiteLink, { DEFAULT_SITE_LINK_STYLING } from '@/components/global/site-link';
import { ClickedOutSide } from '@/helpers/click';
import cn from '@/lib/cn';
import { THEMATIC_GENERAL_EMAIL_LINK } from '@/lib/constants';

const UserHeaderLinks = dynamic(() => import('@/components/global/user-header-links'), { ssr: false });

type SiteHeaderProps = {
    hideLinks?: boolean;
    analystLinkOnly?: boolean;
};

type BasicLink = { text: string | ReactNode; url: string; links?: never; isAnalystLink?: boolean };
type LinkGroup = { text: string | ReactNode; url?: never; links: Array<BasicLink>; isAnalystLink?: never };

const SITE_LINKS: Array<BasicLink | LinkGroup> = [
    {
        isAnalystLink: true,
        text: (
            <div className="flex gap-2 items-center">
                <span>Analyst</span>
                <span className="uppercase text-xs sups bg-brand-blue-nu text-white p-1 rounded">Beta</span>
            </div>
        ),
        url: '/analyst',
    },
    { text: 'Builder', url: '/index-builder/create' },
    {
        links: [
            { text: 'Overview', url: '/product' },
            { text: 'Web3 + Equities', url: '/web3' },
            { text: 'Backtesting & Benchmarking', url: '/l/backtesting-and-benchmarking' },
            { text: 'Thematic Benchmarks', url: '/l/thematic-benchmarks' },
        ],
        text: 'Product',
    },
    { text: 'Indices', url: '/featured-indices' },
    { text: 'Pricing', url: '/pricing' },
    { text: 'Resources', url: '/news-and-resources' },
    { text: 'About', url: '/about-us' },
    { text: 'Contact us 👋', url: THEMATIC_GENERAL_EMAIL_LINK },
];

const SiteHeader = ({ hideLinks = false, analystLinkOnly = false }: SiteHeaderProps) => {
    const router = useRouter();
    const path = router.asPath;
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showDropdown, setShowDropdown] = useState<HTMLElement | null>();
    const dropdownRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        // this effect is only called when showMobileMenu is updated
        // we need to add this property to the body to allow for scrolling the of nav if
        // and when it gets too big
        if (showMobileMenu) {
            document.body.classList.add('mobile-nav-showing');
        } else {
            document.body.classList.remove('mobile-nav-showing');
        }
    }, [showMobileMenu]);

    useEffect(() => {
        if (showDropdown) {
            const linksHolder = showDropdown.getElementsByTagName('menu');
            dropdownRef.current = showDropdown;
            if (linksHolder) {
                linksHolder[0].classList.add('!flex');
            }
        }
    }, [showDropdown]);

    // since we use next.js router to switch pages anytime the url
    // is changes we should hide the mobile menu
    useEffect(() => {
        const handleRouteChange = () => {
            setShowMobileMenu(false);
            hideCurrentDropdown();
        };

        router.events.on('routeChangeStart', handleRouteChange);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [router.events]);

    const hideCurrentDropdown = () => {
        if (dropdownRef.current) {
            const linksHolder = dropdownRef.current.getElementsByTagName('menu');
            if (linksHolder) {
                linksHolder[0].classList.remove('!flex');
            }
            setShowDropdown(null);
            dropdownRef.current = null;
        }
    };

    ClickedOutSide(dropdownRef, hideCurrentDropdown);

    const logo = (
        <Link
            href="/"
            className="flex flex-grow-0 flex-shrink-0 items-center border-none visited:border-none no-underline hover:no-underline"
        >
            <LogoWithWordmark
                color="white"
                size="sm"
                className="mr-6"
            />
        </Link>
    );

    const pageLinks = (
        <>
            {SITE_LINKS.filter((item) => !analystLinkOnly || item.isAnalystLink).map((item, index) => {
                if (item.url) {
                    // this is just a straight link easy build it.
                    return (
                        <SiteLink
                            key={`siteLink-${index}`}
                            url={item.url}
                        >
                            {item.text}
                        </SiteLink>
                    );
                } else if (item.links?.length && item.links.length > 0) {
                    // nested links we need to make the dropdown
                    const id = `dropdown-${index}`;

                    return (
                        <div
                            key={`siteLinkParent-${index}`}
                            className="relative z-20"
                            id={id}
                        >
                            <label
                                className={cn(DEFAULT_SITE_LINK_STYLING, 'flex items-center rounded-t-lg', {
                                    'md:bg-brand-purple-deep': showDropdown?.id === id,
                                })}
                                onClick={(event) => {
                                    if (showDropdown) {
                                        hideCurrentDropdown();
                                    } else {
                                        setShowDropdown(event.currentTarget.parentElement);
                                    }
                                }}
                            >
                                <span
                                    className={cn({
                                        '!text-white': item.links.filter((link) => path.includes(link.url)).length,
                                    })}
                                >
                                    {item.text}
                                </span>
                                <Icon
                                    type="dropDownArrow"
                                    color="blueNu"
                                    size="xxs"
                                    className="ml-2 mt-1 hidden md:block"
                                />
                            </label>
                            <menu className="md:hidden md:absolute pl-4 md:p-4 md:bg-brand-purple-deep rounded-r-lg rounded-bl-lg flex-col">
                                {item.links.map((nestedItem, index) => {
                                    return (
                                        <li
                                            key={`siteLink-${item.text}-${index}`}
                                            className="flex-shrink-0 whitespace-nowrap mb-3"
                                        >
                                            <SiteLink url={nestedItem.url}>{nestedItem.text}</SiteLink>
                                        </li>
                                    );
                                })}
                            </menu>
                        </div>
                    );
                } else {
                    return <></>;
                }
            })}
        </>
    );

    let menu = (
        <Icon
            type="hamburgerMenu"
            color="white"
            size="lg"
            className="mt-1"
        />
    );
    if (showMobileMenu) {
        menu = (
            <Icon
                type="close"
                color="white"
                size="lg"
            />
        );
    }

    const mobileMenuToggle = (
        <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="flex items-center"
        >
            {menu}
        </button>
    );

    // this is the standard site header with responsive menu option
    let header = (
        <div className="w-full">
            <div className="bg-brand-black-alt flex items-center px-4 lg:px-10 justify-between lg:justify-start py-4">
                {logo}
                <div className="hidden lg:flex grow">
                    {!hideLinks && <div className="flex items-center justify-center w-full">{pageLinks}</div>}
                </div>
                <div className="hidden lg:flex">
                    <UserHeaderLinks />
                </div>
                <div className="lg:hidden">{mobileMenuToggle}</div>
            </div>
        </div>
    );

    if (showMobileMenu) {
        header = (
            <div className="bg-brand-black-alt absolute w-full h-full text-white z-20 overflow-y-auto">
                <div className="flex flex-col">
                    <div className="flex justify-between items-center h-14 px-10 flex-shrink-0">
                        {logo}
                        {mobileMenuToggle}
                    </div>
                    <div className="flex flex-col border-b border-b-brand-purple-deep">
                        <UserHeaderLinks expand={showMobileMenu} />
                        <div className="flex flex-col mt-6">{pageLinks}</div>
                    </div>
                    <div className="flex flex-col px-7 py-10">
                        <LearnLinks />
                        <ProductLinks />
                        <CompanyLinks />
                        <div className="text-sm text-brand-gray-dark">
                            <LegalLinks />
                            <CopyWrite />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <div className="font-brand-md text-sm flex flex-col">{header}</div>;
};

export default SiteHeader;
