'use client';

import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useGlobalState } from '@/components/global/global-state';
import { IS_DEV } from '@/lib/constants';
import { GQL_CLIENT } from '@/lib/graphql';
import * as gtag from '@/lib/gtag';
import { CURRENT_USER_QUERY, CurrentUserType } from '@/queries/current-user';

type Props = {
    children: React.ReactNode;
};

let logRocketIsInitialized = false;

// Initialize LogRocket and set the user
function handleLogRocket(user: CurrentUserType | null, routerAsPath: string) {
    // Do not send dev sessions to logrocket
    if (IS_DEV) return;
    // Do not send admin page sessions to logrocket
    if (routerAsPath.startsWith('/admin/')) return;
    if (!logRocketIsInitialized) {
        logRocketIsInitialized = true;
        LogRocket.init('jovyyb/thematic');
        LogRocket.getSessionURL((sessionURL) => {
            Sentry.configureScope((scope) => {
                scope.setExtra('sessionURL', sessionURL);
            });
        });
    }
    if (!user) {
        return;
    }
    // I'm purposefully not de-identifying the user on logout/changing account.
    LogRocket.identify(user.id, { email: user.email });
}

function handleSentry(user: CurrentUserType | null) {
    if (user && user.isThematicAdmin) {
        Sentry.setUser({ email: user.email, id: user.id });
    } else if (user) {
        Sentry.setUser({ id: user.id });
    } else {
        Sentry.setUser(null);
    }
}

const CurrentUser = ({ children }: Props) => {
    const pathname = usePathname();
    const { setGlobalState } = useGlobalState();

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        GQL_CLIENT.request(CURRENT_USER_QUERY)
            .then((data: { currentUser: Optional<CurrentUserType> }) => {
                const user = data.currentUser;
                handleLogRocket(user, pathname as string);
                handleSentry(user);
                if (user) {
                    gtag.setUser(user.id);
                } else {
                    gtag.setUser('');
                }

                setGlobalState((prev) => {
                    return {
                        ...prev,
                        currentUser: user,
                        currentUserLoading: false,
                    };
                });
            })
            .catch((error) => {
                toast.error(error);
            });
    }, [setGlobalState, pathname]);

    return <>{children}</>;
};

export default CurrentUser;
