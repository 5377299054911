import BaseModal from '@/components/global/base-modal';
import LogInOrCreateForm from '@/components/global/log-in-or-create-form';
import { CurrentUserType } from '@/queries/current-user';

export enum OpenWithTypes {
    LOGIN = 'log-in',
    SIGNUP = 'sign-up',
}

type LogInOrCreateModalProps = {
    isOpen: boolean;
    closeFn: () => void;
    signUpCopy?: React.ReactNode;
    logInCopy?: React.ReactNode;
    openWith?: `${OpenWithTypes}`;
    onLoginSuccess?: (user: CurrentUserType) => void;
};

const LogInOrCreateModal = ({
    closeFn,
    isOpen,
    signUpCopy,
    logInCopy,
    onLoginSuccess,
    openWith = 'sign-up',
}: LogInOrCreateModalProps) => {
    return (
        <BaseModal
            isOpen={isOpen}
            onRequestClose={closeFn}
            header="Welcome to Thematic"
            headerClassName="text-center"
            className="rounded-lg border border-analyst-dark-lavender shadow-lg pt-4"
            size="1/3"
        >
            <div className="px-8 pb-8">
                <LogInOrCreateForm {...{ logInCopy, onLoginSuccess, openWith, signUpCopy }} />
            </div>
        </BaseModal>
    );
};
export default LogInOrCreateModal;
