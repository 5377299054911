'use client';

import { useRouter } from 'next/navigation';
import React, { RefObject, useCallback, useEffect, useState } from 'react';
import { useIntersection } from 'react-use';

import Button from '@/components/global/button';
import { useGlobalState } from '@/components/global/global-state';
import { NavigationLink } from '@/components/global/link';
import SignupLoginButtonModal from '@/components/global/signup-login-button-modal';
import TabNavigation, { type TabLink } from '@/components/global/tab-navigation';
import ThematicLogoLink from '@/components/global/thematic-logo-link';
import { TryForYourselfLinkButton } from '@/components/global/try-for-yourself-cta';
import UserProfileDropdown from '@/components/global/user-profile-dropdown';
import IconButton from '@/components/ui/IconButton';
import { logOutCurrentUser } from '@/helpers/logout';
import cn from '@/lib/cn';

const mainNavTabs = [
    {
        href: '/product',
        label: 'Product',
        tabIndex: 2,
    },
    {
        href: '/about-us',
        label: 'About Us',
        tabIndex: 3,
    },
    // {
    //     href: '/',
    //     label: 'Docs',
    //     tabIndex: 4,
    // },
    {
        href: '/news-and-resources',
        label: 'Blog',
        tabIndex: 5,
    },
];

interface BaseHeaderProps {
    className?: string;
}

type HeaderWithIntersectionProps = BaseHeaderProps & {
    intersectionClassName?: string;
    intersectionRef: RefObject<HTMLElement>;
    condenseLogoOnScroll?: boolean;
};

interface MobileNavProps {
    id: string;
    tabs: Array<TabLink>;
}

const MobileNav = ({ id, tabs }: MobileNavProps) => {
    const router = useRouter();
    const { globalState, setGlobalState } = useGlobalState();
    const [showNavigation, setShowNavigation] = useState(false);
    const toggleNavigation = () => setShowNavigation(!showNavigation);
    const closeNavigation = () => setShowNavigation(false);

    return (
        <menu className="relative w-full max-w-sm mx-auto md:hidden">
            <TabNavigation
                id="main-nav"
                className="justify-between items-center w-full"
                startCTA={
                    <ThematicLogoLink
                        className="mr-8"
                        tabIndex={1}
                    />
                }
                endCTA={
                    <IconButton
                        iconProps={{
                            className: 'w-6 h-full text-white flex items-center justify-center',
                            iconClassName: 'w-full',
                        }}
                        onClick={toggleNavigation}
                        onTouchEnd={toggleNavigation}
                        iconType="hamburgerMenu"
                        tabIndex={2}
                    />
                }
                tabs={[]}
            />

            <aside
                className={cn(
                    'fixed top-0 right-0 z-50 w-full bg-analyst-black h-full p-6 translate-x-full transition-transform duration-300 flex flex-col items-start',
                    {
                        'translate-x-0 transition-transform duration-300': showNavigation,
                    }
                )}
            >
                <header className="flex items-center justify-between w-full mb-10">
                    <ThematicLogoLink
                        condenseLogo={true}
                        tabIndex={1}
                        className="w-4"
                    />
                    <IconButton
                        iconProps={{
                            className: 'w-5 h-full text-white flex items-center justify-center',
                            iconClassName: 'w-full',
                        }}
                        className="self-end"
                        onClick={closeNavigation}
                        onTouchEnd={closeNavigation}
                        iconType="close"
                        tabIndex={2}
                    />
                </header>

                {globalState.currentUser && (
                    <UserProfileDropdown
                        className={cn('opacity-100 mb-10 md:mb-0 md:hidden lg:opacity-0')}
                        menuClassName="absolute top-full translate-y-4 w-48 left-0 rounded-lg"
                        toggleClassName="text-white"
                        hideLogout={true}
                    />
                )}

                <nav className="flex flex-col gap-4 items-start mb-10 w-full">
                    {tabs.map(({ label, href, className, tabIndex }, index: number) => {
                        return (
                            <NavigationLink
                                key={`tab-navigation-link-${id}=${index}`}
                                tabIndex={tabIndex}
                                className={cn(
                                    'text-analyst-lavender visited:text-analyst-white hover:text-analyst-white transition-colors',
                                    className
                                )}
                                href={href}
                            >
                                {label}
                            </NavigationLink>
                        );
                    })}

                    <TryForYourselfLinkButton
                        className="w-full mt-4"
                        tabIndex={7}
                    />
                </nav>

                <footer className="flex flex-col gap-4 items-start mt-auto  w-full">
                    {globalState.currentUser ? (
                        <Button
                            type="action"
                            color="transparent"
                            className={cn(
                                'rounded-full border border-analyst-gray px-4 py-2 font-brand-md text-analyst-black hover:bg-analyst-lavender transition-colors bg-white w-full'
                            )}
                            onClick={() => logOutCurrentUser({ onSuccess: router.refresh, setGlobalState })}
                            key="logout"
                        >
                            Log out
                        </Button>
                    ) : (
                        <SignupLoginButtonModal
                            tabIndex={9}
                            className="w-full"
                            buttonClassName="w-full bg-opacity-100"
                        />
                    )}
                </footer>
            </aside>
        </menu>
    );
};

export const MainHeader = ({ className }: BaseHeaderProps) => {
    const { globalState } = useGlobalState();

    return (
        <header
            className={cn('flex items-center justify-center top-0 left-0 z-[100] px-10 pt-5 fixed w-full', className)}
        >
            <MobileNav
                id="main-mobile-nav"
                tabs={mainNavTabs}
            />
            <TabNavigation
                id="main-nav"
                className="justify-between hidden md:flex"
                startCTA={
                    <ThematicLogoLink
                        className="mr-8"
                        tabIndex={1}
                    />
                }
                endCTA={
                    <TryForYourselfLinkButton
                        className="ml-8"
                        tabIndex={7}
                    />
                }
                tabs={mainNavTabs}
            />

            {globalState.currentUser ? (
                <UserProfileDropdown
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            ) : (
                <SignupLoginButtonModal
                    tabIndex={9}
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            )}
        </header>
    );
};

export const MainHeaderWithIntersection = ({
    intersectionRef,
    condenseLogoOnScroll,
    className,
}: HeaderWithIntersectionProps) => {
    const { globalState } = useGlobalState();
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
    });
    const [reachedIntersection, setReachedIntersectionState] = useState(false);

    const updateReachedIntersection = useCallback(() => {
        const yPos = intersectionRef.current?.getBoundingClientRect().y || 0;

        if (yPos <= 0 && !reachedIntersection) {
            setReachedIntersectionState(true);
        } else if (yPos > 0 && reachedIntersection) {
            setReachedIntersectionState(false);
        }
    }, [intersectionRef, reachedIntersection]);

    useEffect(() => {
        intersection && updateReachedIntersection();
    }, [intersection, updateReachedIntersection]);

    return (
        <header
            className={cn(
                'flex items-center justify-center top-0 left-0 z-[100] px-10 pt-5 fixed w-full',
                { 'pt-0 transition-all': reachedIntersection },
                className
            )}
        >
            <MobileNav
                id="main-mobile-nav"
                tabs={mainNavTabs}
            />
            <TabNavigation
                id="main-nav"
                className="justify-between hidden md:flex"
                startCTA={
                    <ThematicLogoLink
                        condenseLogo={condenseLogoOnScroll && reachedIntersection}
                        className="mr-8"
                        tabIndex={1}
                    />
                }
                endCTA={
                    <TryForYourselfLinkButton
                        className="ml-8"
                        tabIndex={7}
                    />
                }
                tabs={mainNavTabs}
            />

            {globalState.currentUser ? (
                <UserProfileDropdown
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            ) : (
                <SignupLoginButtonModal
                    tabIndex={9}
                    className={cn(
                        'hidden opacity-0 md:block lg:opacity-100 md:absolute md:top-1/2 md:z-10 md:-translate-y-1/2 md:last:mt-2.5 md:right-10'
                    )}
                />
            )}
        </header>
    );
};

export default MainHeader;
