import Link from 'next/link';

import { LogoWithWordmark } from '@/components/global/logo';
import { THEMATIC_GENERAL_EMAIL_LINK, THEMATIC_TWITTER_URL } from '@/lib/constants';

type FooterLinksProps = {
    title: string;
    links: Array<{
        text: string;
        url: string;
    }>;
    titleLink: string;
};
const FooterLinks = ({ title, links, titleLink }: FooterLinksProps) => {
    return (
        <div className="mr-24 last:mr-0 flex-grow mb-12">
            <Link
                href={titleLink}
                className="font-brand-md !text-white !hover:text-white no-underline mb-5 block hover:underline"
            >
                {title}
            </Link>
            <ul>
                {links.map((item, index) => {
                    return (
                        <li
                            className="mb-3"
                            key={`link-${index}`}
                        >
                            <Link
                                href={item.url}
                                target={item.url.indexOf('http') !== -1 ? '_blank' : undefined}
                                rel={item.url.indexOf('http') !== -1 ? 'noreferrer' : undefined}
                                className="hover:underline no-underline !text-white !hover:text-white"
                            >
                                {item.text}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export const LearnLinks = () => {
    const links = [
        {
            text: 'Introducing Thematic',
            url: '/news-and-resources/backed-by-lux-capital-thematic-launches-to-democratize-institutional-quality-index-and-etf-capabilities',
        },
        {
            text: 'How Thematic Works',
            url: '/news-and-resources/how-thematic-works',
        },
        {
            text: 'How to Build Your Own Stock Index',
            url: '/news-and-resources/how-to-build-your-own-stock-index',
        },
    ];

    return (
        <FooterLinks
            title="Learn"
            links={links}
            titleLink="/news-and-resources"
        />
    );
};

export const CompanyLinks = () => {
    const links = [
        { text: 'About us', url: '/about-us' },
        { text: 'Contact us', url: THEMATIC_GENERAL_EMAIL_LINK },
        { text: 'Thematic on Twitter', url: THEMATIC_TWITTER_URL },
    ];

    return (
        <FooterLinks
            title="Company"
            links={links}
            titleLink="/about-us"
        />
    );
};

export const ProductLinks = () => {
    const links = [
        { text: 'Portfolio Builder', url: '/index-builder/create' },
        { text: 'Featured Portfolios and Indices', url: '/featured-indices' },
        { text: 'Web3 Indices', url: '/web3' },
    ];

    return (
        <FooterLinks
            title="Product"
            links={links}
            titleLink="/featured-indices"
        />
    );
};

export const CopyWrite = () => {
    return <p className="mr-2">&copy; {new Date().getFullYear()} Thematic Capital, Inc. All rights reserved.</p>;
};

export const LegalLinks = () => {
    const links = [
        { text: 'Terms & Conditions', url: '/terms-and-conditions' },
        { text: 'Privacy Policy', url: '/privacy-policy' },
    ];

    return (
        <>
            {links.map((item, index) => {
                return (
                    <Link
                        href={item.url}
                        key={`legal-link-${index}`}
                        className="no-underline hover:underline !text-brand-gray-dark visited:text-brand-gray-dark mr-2"
                    >
                        {item.text}
                    </Link>
                );
            })}
        </>
    );
};

const Footer = () => {
    return (
        <footer className="site-footer bg-brand-black-alt text-white p-11">
            <div className="flex items-center mb-12">
                <LogoWithWordmark
                    color="white"
                    size="sm"
                    className="mr-6"
                />
            </div>
            <div className="flex flex-col md:flex-row">
                <div className="flex flex-col sm:flex-row items-start">
                    <CompanyLinks />
                    <ProductLinks />
                    <LearnLinks />
                </div>
            </div>

            <div className="text-sm text-brand-gray-dark flex">
                <CopyWrite />
                <LegalLinks />
            </div>
        </footer>
    );
};

export default Footer;
