import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';

import Icon from '@/components/global/icon';
import cn from '@/lib/cn';

type SiteLinkProps = {
    children: string | ReactNode;
    url: string;
    className?: string;
    currentPath?: string;
    hasCaret?: boolean;
};

export const DEFAULT_SITE_LINK_STYLING =
    'text-brand-gray-shell visited:text-brand-gray-shell mr-1 no-underline hover:text-white py-4 md:py-2 px-3 mb-0 font-brand-med w-full lg:w-auto flex-shrink-0 cursor-pointer';

const SiteLink = ({ children, url, currentPath, hasCaret = false, className }: SiteLinkProps) => {
    const pathname = usePathname();
    const path = currentPath || pathname;

    return (
        <Link
            href={url}
            className={cn(
                DEFAULT_SITE_LINK_STYLING,
                cn({
                    '!text-white': path?.includes(url),
                }),
                className
            )}
        >
            {children}
            {hasCaret && (
                <Icon
                    type="caret"
                    color="blueNu"
                    size="custom"
                    rotate={180}
                    className="ml-1.5 h-[9px] w-[5px] hidden md:inline-block"
                />
            )}
        </Link>
    );
};

export default SiteLink;
