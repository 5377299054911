import Link from 'next/link';

type TOSMessagingProps = {
    clickedString: string;
};

const TOSMessaging = ({ clickedString }: TOSMessagingProps) => {
    return (
        <div className="mb-6 text-sm text-brand-gray-dark">
            {`By clicking "${clickedString}", you agree to our `}
            <Link
                href="/terms-and-conditions"
                target="_blank"
                className="font-brand-bold !text-brand-blue-yves"
            >
                Terms
            </Link>{' '}
            and{' '}
            <Link
                href="/privacy-policy"
                target="_blank"
                className="font-brand-bold !text-brand-blue-yves"
            >
                Privacy Policy
            </Link>
            .
        </div>
    );
};

export default TOSMessaging;
