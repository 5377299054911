import { forwardRef, Ref } from 'react';

import { NavigationLink } from '@/components/global/link';
import cn from '@/lib/cn';

export interface TabLink {
    label: React.ReactNode;
    href: string;
    onClick?: () => void;
    className?: string;
    tabIndex?: number;
}
export interface TabNavigationProps {
    tabs: Array<TabLink>;
    className?: string;
    id: string;
    startCTA?: React.ReactNode;
    endCTA?: React.ReactNode;
}

const TabNavigation = forwardRef(function TabNavigation(
    { startCTA: StartCTA, endCTA: EndCTA, id, className, tabs = [] }: TabNavigationProps,
    ref: Ref<HTMLElement>
) {
    return (
        <nav
            ref={ref}
            id={id}
            className={cn('bg-analyst-black px-5 py-2.5 rounded-full flex items-center', className)}
        >
            {StartCTA}

            <div className="flex items-center gap-5">
                {tabs.map(({ label, href, className, tabIndex }, index: number) => {
                    return (
                        <NavigationLink
                            key={`tab-navigation-link-${id}=${index}`}
                            tabIndex={tabIndex}
                            className={cn(
                                'text-analyst-lavender visited:text-analyst-lavender hover:text-analyst-white transition-colors',
                                className
                            )}
                            href={href}
                        >
                            {label}
                        </NavigationLink>
                    );
                })}
            </div>
            {EndCTA}
        </nav>
    );
});

export default TabNavigation;
