import { Nullable } from '@/types/nullable';

export const IS_DEV = process.env.NEXT_PUBLIC_IS_DEV === 'true';

export const SITE_NAME = 'Thematic';
export const SITE_BASE_URL = IS_DEV ? 'http://localhost:8080' : 'https://www.gothematic.com';

export const COMMIT_HASH = process.env.COMMIT_HASH ?? 'unset';

export const THEMATIC_TWITTER_USERNAME = 'go_thematic';
export const THEMATIC_TWITTER_URL = `https://twitter.com/${THEMATIC_TWITTER_USERNAME}`;
export const THEMATIC_TWITTER_HANDLE = `@${THEMATIC_TWITTER_USERNAME}`;

const THEMATIC_GENERAL_EMAIL_HANDLE = 'hello';
const THEMATIC_SOLUTIONS_EMAIL_HANDLE = 'solutions';
const THEMATIC_EMAIL_DOMAIN = 'gothematic.com';

export const THEMATIC_GENERAL_EMAIL = `${THEMATIC_GENERAL_EMAIL_HANDLE}@${THEMATIC_EMAIL_DOMAIN}`;
export const THEMATIC_SOLUTIONS_EMAIL = `${THEMATIC_SOLUTIONS_EMAIL_HANDLE}@${THEMATIC_EMAIL_DOMAIN}`;

export const THEMATIC_GENERAL_EMAIL_LINK = `mailto:${THEMATIC_GENERAL_EMAIL}`;
export const THEMATIC_SOLUTIONS_EMAIL_LINK = `mailto:${THEMATIC_SOLUTIONS_EMAIL}`;

export const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT ?? '';
export const API_GRAPHQL_ENDPOINT = `${API_ENDPOINT}/graphql` ?? '';
export const API_ADMIN_GRAPHQL_ENDPOINT = `${API_ENDPOINT}/admin/graphql` ?? '';

export const BUILD_ENDPOINT = process.env.NEXT_PUBLIC_BUILD_ENDPOINT ?? '';
export const BUILD_GRAPHQL_ENDPOINT = `${BUILD_ENDPOINT}/graphql` ?? '';

export const BLOG_GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_BLOG_ENDPOINT ?? '';

export const DEFAULT_INDEX_BUILDER_BENCHMARK_INDEX_IDS = process.env.NEXT_PUBLIC_TMLARGE_BENCHMARK_ID
    ? [process.env.NEXT_PUBLIC_TMLARGE_BENCHMARK_ID]
    : [];

export const ALL_SAMPLE_PROMPTS = [
    'Cloud based storage companies',
    'Space tourism',
    'Female leaders in finance',
    'Companies that Bill Ackman of Pershing Square Capital might invest in during uncertain economic times',
];

export const parseFundamentalDecimalValue = (value: Nullable<string>, decimalPlaces = 2): string | string => {
    const numberValue = parseFloat(value ?? '');
    if (isNaN(numberValue)) {
        return 'N/A';
    }

    return `${numberValue.toFixed(decimalPlaces)}`;
};

function abbrNum(number: number | string, decPlaces: number): string {
    if ((number as string).length) {
        number = parseFloat(number as string);
    }

    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    const abbrev = ['K', 'M', 'B', 'T'];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {
        // Convert array index to "1000", "1000000", etc
        const size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= (number as number)) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = Math.round(((number as number) * decPlaces) / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if (number === 1000 && i < abbrev.length - 1) {
                number = 1;
                i++;
            }

            // Add the letter for the abbreviation
            number = `${number}${abbrev[i]}`;

            // We are done... stop
            break;
        }
    }

    return `${number}`;
}

export interface FundamentalsMappingType {
    acroynm: Nullable<string>;
    label: string;
    name: string;
    formatter?: (value: Nullable<string>) => string;
}

type FundamentalValueFormatType = Pick<FundamentalsMappingType, 'formatter'> & {
    value: Nullable<string>;
    units?: {
        isPrefix?: boolean;
        isSuffix?: boolean;
        value: Nullable<string>;
    };
};

export const formatFundamentalValue = ({ value, formatter, units }: FundamentalValueFormatType): string => {
    const formattedValue = formatter ? formatter(value) : value;

    if (formattedValue && formattedValue !== 'N/A') {
        if (!units) {
            return formattedValue;
        }

        if (units.isPrefix) {
            return `${units.value}${formattedValue}`;
        }

        if (units.isSuffix) {
            return `${formattedValue}${units.value}`;
        }
    }

    return 'N/A';
};

export const FUNDAMENTALS_MAPPING: {
    [key: string]: FundamentalsMappingType;
} = {
    earnings_per_share: {
        acroynm: 'EPS',
        formatter: (value: Nullable<string>) =>
            formatFundamentalValue({
                formatter: parseFundamentalDecimalValue,
                units: {
                    isPrefix: true,
                    value: '$',
                },
                value,
            }),
        label: 'EPS',
        name: 'Earnings Per Share',
    },
    ebitda_margin: {
        acroynm: 'EBITDA',
        formatter: (value: Nullable<string>) =>
            formatFundamentalValue({
                formatter: parseFundamentalDecimalValue,
                units: {
                    isSuffix: true,
                    value: '%',
                },
                value,
            }),
        label: 'EBITDA Mg.',
        name: 'EBITDA Margin',
    },
    enterprise_value_revenue_ratio: {
        acroynm: null,
        formatter: (value: Nullable<string>) =>
            formatFundamentalValue({ formatter: parseFundamentalDecimalValue, value }),
        label: 'Ent. Val/Rev',
        name: 'Enterprise Value Revenue Ratio',
    },
    float_as_percentage_of_shares_outstanding_usd: {
        acroynm: null,
        formatter: (value: Nullable<string>) =>
            formatFundamentalValue({
                formatter: parseFundamentalDecimalValue,
                units: {
                    isSuffix: true,
                    value: '%',
                },
                value,
            }),
        label: 'Float %',
        name: 'Float as % of Shares Outstanding',
    },
    gross_income_margin: {
        acroynm: null,
        label: 'Gross Margin',
        name: 'Gross Margin',
    },
    market_capitalization_usd: {
        acroynm: null,
        formatter: (value: Nullable<string>) =>
            formatFundamentalValue({
                formatter: () => abbrNum(parseFloat(value ?? '0'), 2),
                units: {
                    isPrefix: true,
                    value: '$',
                },
                value,
            }),
        label: 'Market Cap',
        name: '1 yr Revenue Growth Rate',
    },
    net_revenue_retention: {
        acroynm: 'NRR',
        label: 'NRR',
        name: 'Net Revenue Retention',
    },
    one_year_annual_revenue_growth_rate: {
        acroynm: null,
        formatter: (value: Nullable<string>) =>
            formatFundamentalValue({
                formatter: parseFundamentalDecimalValue,
                units: {
                    isSuffix: true,
                    value: '%',
                },
                value,
            }),
        label: 'Rev Growth',
        name: '1 yr Annual Revenue Growth Rate',
    },
    price_earnings_ratio: {
        acroynm: 'P/E',
        formatter: (value: Nullable<string>) => parseFundamentalDecimalValue(value),
        label: 'P/E Ratio',
        name: 'Price to Earnings Ratio',
    },
    return_on_total_capital: {
        acroynm: 'ROTC',
        formatter: (value: Nullable<string>) => parseFundamentalDecimalValue(value),
        label: 'ROTC',
        name: 'Return on Total Capital',
    },
};

export const BUSINESS_FUNDAMENTALS = [
    'sales_or_revenue',
    'one_year_annual_revenue_growth_rate',
    'net_income',
    'gross_income_margin',
    'ebitda_margin',
    'ebitda',
    'total_enterprise_value',
    'enterprise_value_revenue_ratio',
    'price_earnings_ratio',
    'price_to_sales_ratio',
    'price_to_book_ratio',
    'return_on_total_capital',
    'return_on_assets',
    'return_on_total_equity',
    'dividend_yield_daily_percent',
    'price_three_month_sharpe_ratio',
    'price_three_month_rolling_volatility',
    'price_three_month_standard_deviation',
    'price_three_month_drawdown',
    'beta',
];

export const TRADING_FUNDAMENTALS = [
    'market_capitalization_usd',
    'float_as_percentage_of_shares_outstanding_usd',
    'average_daily_volume_last_month',
    'average_daily_volume_trailing_three_months_usd',
    'earnings_per_share',
];

export const ANALYST_ID_QUERY_PARAM = 'aid';
export const EVALUTION_REQUEST_ID_QUERY_PARAM = 'rid';
