'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect } from 'react';

import { useGlobalState } from '@/components/global/global-state';

interface ThematicPostHogProviderProps {
    children: React.ReactNode;
}

const usePosthogInit = () => {
    const { globalState } = useGlobalState();
    const { currentUser, currentUserLoading } = globalState;

    useEffect(() => {
        if (!currentUserLoading && typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
            // checks that we are client-side
            posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
                api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
                loaded: (posthog) => {
                    currentUser?.email && posthog.identify(currentUser?.email);

                    if (process.env.NODE_ENV === 'development') {
                        posthog.debug(); // debug mode in development
                    }
                },
                person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
            });
        }
    }, [currentUser, currentUserLoading]);
};

const ThematicPostHogProvider = ({ children }: ThematicPostHogProviderProps) => {
    usePosthogInit();
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};

export default ThematicPostHogProvider;
