import * as Sentry from '@sentry/nextjs';
import { gql } from 'graphql-request';
import { toast } from 'react-toastify';

import { GlobalStateInterface } from '@/components/global/global-state';
import { GQL_CLIENT } from '@/lib/graphql';
import * as gtag from '@/lib/gtag';

export const logOutCurrentUser = ({
    setGlobalState,
    onSuccess,
}: {
    setGlobalState: ReactStateSetter<Partial<GlobalStateInterface>>;
    onSuccess?: () => void;
}) => {
    const query = gql`
        mutation signOut {
            logOutUserSession {
                message
                success
            }
        }
    `;

    setGlobalState((prev) => {
        return {
            ...prev,
            // Simulate loading to avoid user-centric handling on authenticated routes
            currentUserLoading: true,
        };
    });

    const data = GQL_CLIENT.request(query);

    data.then((data: { logOutUserSession: { message: string; success: boolean } }) => {
        if (data.logOutUserSession.success) {
            toast.success(data.logOutUserSession.message);
            Sentry.setUser(null);
            gtag.setUser('');
            setGlobalState((prev) => {
                return {
                    ...prev,
                    currentUser: undefined,
                    currentUserLoading: false,
                };
            });

            if (onSuccess) onSuccess();
        } else {
            toast.error(data.logOutUserSession.message);
        }
    });
};
