import LinkedInLogo from '@/assets/logos/linkedin-icon-logo.svg';
import XLogo from '@/assets/logos/x-icon-logo.svg';
import { Paragraph } from '@/components/dom/text-elements';
import { Container, ContainerSize } from '@/components/global/container';
import { NavigationLink } from '@/components/global/link';
import ThematicLogoLink from '@/components/global/thematic-logo-link';
import cn from '@/lib/cn';

interface MainFooterProps {
    className?: string;
}

interface ColumnHeadingProps {
    children: React.ReactNode;
    className?: string;
}

const ColumnHeading = ({ children, className }: ColumnHeadingProps) => (
    <Paragraph
        className={cn('text-analyst-black text-lg md:text-xl xl:text-[22px] leading-1 font-brand-md mb-0', className)}
    >
        {children}
    </Paragraph>
);

const MainFooter = ({ className }: MainFooterProps) => {
    return (
        <footer className={cn('py-4 md:pt-10 md:pb-20', className)}>
            <Container
                size={ContainerSize['7xl']}
                className="flex flex-wrap flex-col gap-y-8 lg:flex-row items-start justify-between lg:gap-1"
            >
                <div className="flex flex-col gap-5 lg:flex-row">
                    <ThematicLogoLink
                        useCubeLogo={true}
                        className="bg-analyst-black rounded-full w-16 h-16 xl:w-20 xl:h-20 p-2 flex items-center justify-center col-span-1"
                        iconClassName="fill-white h-8 xl:h-12"
                        iconInnerWrapperClassName="items-center justify-center"
                    />

                    <div className="flex flex-col gap-5">
                        <ColumnHeading>Follow our journey and product updates</ColumnHeading>

                        <div className="flex gap-5">
                            <NavigationLink
                                href="https://www.linkedin.com/company/gothematic/"
                                title="Thematic - LinkedIn Page"
                                target="_blank"
                            >
                                <LinkedInLogo className="w-8 h-8" />
                            </NavigationLink>
                            <NavigationLink
                                href="https://twitter.com/go_thematic"
                                title="Thematic - X/Twitter Page"
                                target="_blank"
                            >
                                <XLogo className="w-8 h-8" />
                            </NavigationLink>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row flex-wrap gap-y-5 gap-x-10 lg:gap-x-16 md:justify-between">
                    <div className="flex flex-col gap-5">
                        <ColumnHeading>Product</ColumnHeading>

                        <NavigationLink
                            href="/analyst"
                            title="Thematic - Analyst"
                            className="font-brand-md text-analyst-dark-lavender visited:text-analyst-dark-lavender"
                        >
                            Thematic Analyst
                        </NavigationLink>
                    </div>
                    <div className="flex flex-col gap-5">
                        <ColumnHeading>Company</ColumnHeading>
                        <NavigationLink
                            href="/about-us"
                            title="Thematic - About Us"
                            className="font-brand-md text-analyst-dark-lavender"
                        >
                            About
                        </NavigationLink>
                        <NavigationLink
                            href="mailto:hello@gothematic.com"
                            title="Thematic - Contact Us"
                            className="font-brand-md text-analyst-dark-lavender"
                        >
                            Contact
                        </NavigationLink>
                        {/* <NavigationLink
                    href="/analyst"
                    title="Thematic Analyst"
                >
                    Docs
                </NavigationLink> */}
                    </div>
                    <div className="flex flex-col gap-5 w-full md:w-auto">
                        <ColumnHeading>Legal</ColumnHeading>
                        <NavigationLink
                            href="/terms-and-conditions"
                            title="Thematic - Terms and Conditions"
                            className="font-brand-md text-analyst-dark-lavender"
                        >
                            Terms
                        </NavigationLink>
                        {/* <NavigationLink
                    href="/analyst"
                    title="Thematic Analyst"
                >
                    AI Terms
                </NavigationLink> */}
                        <NavigationLink
                            href="/privacy-policy"
                            title="Thematic - Privacy Policy"
                            className="font-brand-md text-analyst-dark-lavender"
                        >
                            Privacy Policy
                        </NavigationLink>
                    </div>
                </div>
            </Container>
        </footer>
    );
};

export default MainFooter;
