import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useIndexBuilderContext } from '@/components/index-builder/useIndexBuilderContext';
import cn from '@/lib/cn';
import { THEMATIC_GENERAL_EMAIL_LINK } from '@/lib/constants';

const IndexBuilderNav = () => {
    const router = useRouter();
    const { builderIndexId, hasRulesBasedStrategy } = useIndexBuilderContext();
    const [path, setPath] = useState('');

    useEffect(() => {
        if (!router.isReady) return;

        setPath(router.asPath.split('?')[0]);
    }, [router.isReady, router.asPath]);

    const steps = [
        hasRulesBasedStrategy
            ? { title: 'Rules', url: `/index-builder/${builderIndexId}/rules` }
            : { title: 'Select', url: `/index-builder/${builderIndexId}/search` },
        { title: 'Build', url: `/index-builder/${builderIndexId}/build` },
        { title: 'Save', url: `/index-builder/${builderIndexId}/publish` },
    ];
    if (!hasRulesBasedStrategy && builderIndexId === 'create') {
        steps.unshift({ title: 'Search', url: `/index-builder/${builderIndexId}` });
    }

    return (
        <div className="flex flex-col bg-brand-navy text-white justify-between w-48 pt-16">
            <div>
                <ol>
                    {steps.map((item, index) => {
                        return (
                            <li key={`step-${index}`}>
                                <Link
                                    href={item.url}
                                    className={cn(
                                        'block px-6 py-6 w-full !font-brand-bold !text-base text-brand-navy-light !no-underline hover:bg-brand-blue-yves hover:text-brand-navy-light visited:text-brand-navy-light !subpixel-antialiased',
                                        {
                                            'bg-brand-blue-yves': path === item.url,
                                        }
                                    )}
                                >
                                    {item.title}
                                </Link>
                            </li>
                        );
                    })}
                </ol>
            </div>
            <div className="hover:bg-brand-blue-yves">
                <ul className="p-6">
                    <li>
                        <a
                            href={THEMATIC_GENERAL_EMAIL_LINK}
                            className="text-white no-underline hover:text-white visited:text-white "
                        >
                            Contact us
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default IndexBuilderNav;
