import Link from 'next/link';
import React from 'react';

import Icon from '@/components/global/icon';
import cn from '@/lib/cn';

export enum ButtonTypes {
    Link = 'link',
    Submit = 'submit',
    Action = 'action',
}
export interface ButtonCommonProps {
    className?: string;
    shadow?: false | 'dark' | 'light';
    color?:
        | 'primary'
        | 'primary-gradient'
        | 'secondary'
        | 'tertiary'
        | 'danger'
        | 'unicorn'
        | 'white'
        | 'transparent'
        | 'blue-yves'
        | 'blue-nu'
        | 'blue-nu-wire'
        | 'blue-yves-wire-transparent'
        | 'analyst-purple'
        | 'analyst-lavender'
        | 'thematic-purple';
    textPosition?: 'center' | 'left' | 'right';
    roundedCorners?: 'sm' | 'md' | 'lg' | 'full';
    title?: string;
    noPadding?: boolean;
    tabIndex?: number;
}

export interface LinkButtonProps {
    type: 'link';
    href: string;
    children: React.ReactNode;
    onClick?: never;
    value?: never;
    isDisabled?: never;
    openNewWindow?: boolean;
    hasCaret?: boolean;
}

export interface SubmitButtonProps {
    type: 'submit';
    value: string;
    href?: never;
    children?: never;
    onClick?: never;
    isDisabled?: boolean;
    openNewWindow?: never;
    hasCaret?: never;
}

export interface ActionButtonProps {
    type: 'action';
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    children: React.ReactNode;
    href?: never;
    value?: never;
    isDisabled?: boolean;
    openNewWindow?: never;
    hasCaret?: boolean;
}

type ConditionalProps = LinkButtonProps | SubmitButtonProps | ActionButtonProps;

export type ButtonProps = ButtonCommonProps & ConditionalProps;

export const BUTTON_COLOR_MAP = {
    'analyst-black-wire':
        'bg-analyst-white border-2 border-analyst-black hover:bg-analyst-lavender hover:border-analyst-lavender transition-colors !text-white',
    'analyst-lavender':
        'bg-analyst-lavender !text-analyst-purple hover:bg-analyst-purple hover:!text-white transition-colors',
    'analyst-purple':
        'bg-analyst-purple hover:bg-analyst-blue transition-colors !text-white :hover:bg-analyst-lavender',
    'analyst-purple-wire':
        'bg-analyst-white border-2 border-analyst-purple hover:bg-analyst-lavender hover:border-analyst-lavender transition-colors !text-white',
    'blue-nu': 'bg-brand-blue-nu !text-white hover:bg-brand-blue-yves hover:text-white',
    'blue-nu-wire':
        'bg-white text-brand-blue-nu border border-brand-blue-nu hover:border-brand-blue-yves hover:text-brand-blue-yves',
    'blue-yves': 'bg-brand-blue-yves !text-brand-navy-light hover:bg-brand-blue-nu hover:text-white',
    'blue-yves-wire-transparent':
        'bg-transparent text-brand-blue-yves border border-brand-blue-yves hover:border-brand-blue-nu hover:text-brand-blue-nu',
    danger: 'bg-brand-gray !text-brand-gray-med hover:!text-brand-danger hover:bg-brand-danger-light',
    disabled:
        '!border !border-brand-gray-med !bg-brand-gray !text-brand-gray-dark hover:!bg-brand-gray !cursor-default',
    primary: 'bg-brand-navy !text-brand-navy-light hover:bg-brand-blue-yves hover:text-white',
    'primary-gradient':
        'font-brand-md bg-gradient-to-r from-thematic-purple to-thematic-blue text-white hover:text-analyst-lavender-medium visited:text-white transition-colors',
    secondary: 'bg-brand-teal-dark !text-white hover:bg-brand-teal',
    tertiary: 'bg-brand-gray !text-brand-gray-dark border-2 border-brand-dark-gray',
    'thematic-purple':
        'bg-thematic-purple !text-white hover:bg-analyst-dark-lavender hover:text-analyst-dark-lavender transition-colors hover:border-analyst-dark-lavender',
    transparent: 'bg-transparent text-brand-black',
    unicorn: 'bg-brand-unicorn !text-white',
    white: 'bg-white !text-brand-black',
};

const Button = ({
    children = null,
    type,
    className,
    title,
    onClick,
    href,
    value,
    shadow = false,
    isDisabled = false,
    color = 'primary',
    textPosition = 'center',
    openNewWindow,
    roundedCorners,
    hasCaret = false,
    noPadding = false,
    tabIndex,
}: ButtonProps) => {
    const caret = (
        <Icon
            type="caret"
            size="custom"
            rotate={180}
            color="inherit"
            className="ml-1.5 h-[9px] w-[5px]"
        />
    );
    const shadowStyles =
        shadow !== false
            ? cn('transition-shadow', {
                  'shadow-button hover:shadow-button-hover active:shadow-button-click': shadow === 'light',
                  'shadow-button-dark hover:shadow-button-dark-hover active:shadow-button-dark-click':
                      shadow === 'dark',
              })
            : '';
    const colorStyles = isDisabled ? BUTTON_COLOR_MAP.disabled : BUTTON_COLOR_MAP[color];
    const padding = (() => {
        if (noPadding) return '';

        // `border`
        if (['blue-nu-wire', 'blue-yves-wire-transparent'].includes(color) || isDisabled) return 'px-[15px] py-[9px]';

        // `border-2
        if (['tertiary'].includes(color)) return 'px-3.5 py-2';

        // no border
        return 'px-4 py-2.5';
    })();
    const buttonStyles = cn(
        '!no-underline flex items-center !font-brand-med cursor-pointer text-sm rounded-none ' + padding,
        {
            'justify-center': textPosition === 'center',
            'justify-end': textPosition === 'right',
            'justify-start': textPosition === 'left',
        }
    );

    const cornerStyles = cn({
        '!rounded-full': roundedCorners === 'full',
        '!rounded-lg': roundedCorners === 'lg',
        '!rounded-md': roundedCorners === 'md',
        '!rounded-sm': roundedCorners === 'sm',
    });

    if (type === 'action') {
        return (
            <button
                className={cn(buttonStyles, shadowStyles, colorStyles, cornerStyles, className)}
                title={title}
                onClick={onClick}
                disabled={isDisabled}
                tabIndex={tabIndex}
            >
                {children}
                {hasCaret && caret}
            </button>
        );
    } else if (type === 'link') {
        return (
            <Link
                href={href}
                className={cn(buttonStyles, shadowStyles, colorStyles, cornerStyles, className)}
                title={title}
                target={openNewWindow ? '_blank' : undefined}
                rel={openNewWindow ? 'noreferrer noopener' : undefined}
                tabIndex={tabIndex}
            >
                {children}
                {hasCaret && caret}
            </Link>
        );
    } else if (type === 'submit') {
        return (
            <input
                type="submit"
                value={value}
                className={cn(buttonStyles, shadowStyles, colorStyles, cornerStyles, className)}
                title={title}
                disabled={isDisabled}
                tabIndex={tabIndex}
            />
        );
    }

    return (
        <div className="bg-brand-danger text-black py-2 px-4">{`You must select a button type of "link", "submit" or "action".`}</div>
    );
};

export default Button;
